<template>
    <b-col cols="12">
        <div class="card mb-1">
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col-auto" v-if="device.Device.ProviderType == 5 && device.OnlineDevice.OnlineState == true">
                        <!-- Avatar -->
                        <router-link                            
                            class="avatar avatar-online-new avatar-lg"
                            :to="{
                                name: 'door',
                                params: {
                                    locationId: device.Device.LocationId,
                                    doorId: device.Device.Id,
                                    companyId: device.Device.CompanyId,
                                },
                            }"
                        >                        
                            <img
                                :src="device.Device.SmallImageUrl || '/images/zesec-placeholder.png'"                                
                                class="avatar-img rounded"
                            />
                        </router-link>
                    </div>
                    <div class="col-auto" v-else-if="device.Device.ProviderType == 5 && device.OnlineDevice.OnlineState == false">
                        <!-- Avatar -->
                        <router-link                            
                            class="avatar avatar-offline-new avatar-lg"
                            :to="{
                                name: 'door',
                                params: {
                                    locationId: device.Device.LocationId,
                                    doorId: device.Device.Id,
                                    companyId: device.Device.CompanyId,
                                },
                            }"
                        >                        
                            <img
                                :src="device.Device.SmallImageUrl || '/images/zesec-placeholder.png'"                                
                                class="avatar-img rounded"
                            />
                        </router-link>
                    </div>
                    <div class="col-auto" v-else>
                        <router-link
                            class="avatar avatar-lg"
                            :to="{
                                name: 'door',
                                params: {
                                    locationId: device.Device.LocationId,
                                    doorId: device.Device.Id,
                                    companyId: device.Device.CompanyId,
                                },
                            }"
                        >
                        <!-- :src="
                                    device.Device.SmallImageUrl &&
                                    device.Device.SmallImageUrl !=
                                        'https://zesec-prod.s3.eu-central-1.amazonaws.com//_150x150'
                                        ? device.Device.SmallImageUrl
                                        : '/images/zesec-placeholder.png'
                                " -->
                            <img
                                :src="device.Device.SmallImageUrl || '/images/zesec-placeholder.png'"
                                class="avatar-img rounded"
                            />
                        </router-link>
                    </div>
                    <div class="col ml-n2">
                        <!-- Title -->
                        <h4 class="mb-1">
                            <router-link
                                :to="{
                                    name: 'door',
                                    params: {
                                        locationId: device.Device.LocationId,
                                        doorId: device.Device.Id,
                                        companyId: device.Device.CompanyId,
                                    },
                                }"
                                >{{ device.Device.Name }}</router-link
                            >
                        </h4>

                        <!-- Text -->
                        <p class="card-text small text-muted mb-1">
                            {{
                                getDeviceProvider(device.Device.ProviderType)
                                    .key
                            }}
                        </p>

                        <!-- Status -->
                        <!-- <p class="card-text small" v-if="device.Device.Locked">
                            <span class="text-warning">●</span>
                            {{ $t("doors.locked") }}
                        </p>
                        <p class="card-text small" v-else>
                            <span class="text-success">●</span>
                            {{ $t("doors.unlocked") }}
                        </p> -->
                    </div>
                    <div class="col-auto">
                        <small>
                            {{
                                $t("common.count_keys", {
                                    count: device.Keys
                                        ? device.Keys.length
                                        : device.KeysCount,
                                })
                            }}
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </b-col>
</template>
<script>
import { getDeviceProvider } from "@/handlers/enums";

export default {
    name: "door-card-item",
    props: ["device"],
    created() {},
    methods: {
        getDeviceProvider(id) {
            return getDeviceProvider(id);
        },
    },
};
</script>
