<template>
    <div class="main-content">
        <div class="header">
            <div class="container-fluid">
                <!-- Body -->
                <div class="header-body">
                    <div class="row align-items-center">
                        <div class="col">
                            <h6 class="header-pretitle">
                                {{ $t("menu.dashboard") }}
                            </h6>
                            <h1 class="header-title">
                                {{ $t("doors.title_all") }}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-container fluid>
            <b-row>
            <b-col cols="12">
            <div class="card">
            <div class="card-header">
            <!-- <div class="row mb-4"> -->
                <h4 class="card-header-title"></h4>                
                    <form class="mr-3">
                        <Select2
                        v-model="perPage"
                        :options="perPageOptions"
                        :settings="{
                            minimumResultsForSearch: -1,
                            containerCssClass:
                            'custom-select custom-select-sm form-control-flush',
                            dropdownCssClass: 'dropdown-menu dropdown-menu-sm',
                        }"
                        @change="paginatedList"                         
                        />
                    </form>                
                    <form class="mr-3">                         
                        <b-button-group class="form-control-height">
                            <button
                                class="btn btn-white"
                                @click.stop.prevent="pageView('list')"
                                v-bind:class="{ active: viewMode == 'list'}"
                            >
                                <span class="fe fe-list"></span>
                            </button>
                            <button
                                class="btn btn-white"
                               @click.stop.prevent="pageView('card')"
                                v-bind:class="{ active: viewMode == 'card'}"
                            >
                                <span class="fe fe-grid"></span>
                            </button>
                        </b-button-group>
                    </form>
                
            </div>
            <div class="card-header">
          <!-- Form -->
          <form>
            <div class="input-group input-group-flush input-group-merge">
              <!-- Input -->

              <b-form-input
                :placeholder="$t('common.search')"
                v-model="filter"
                class="form-control form-control-prepended search"
                type="search"
              ></b-form-input>

              <!-- Prepend -->
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <span class="fe fe-search"></span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-body">
            <!-- <div class="tab-content"> -->
                <!-- <transition name="fade" mode="out-in"> -->
                    <b-pagination
                    v-model="currentPage"
                    :total-rows="devicesList.length"
                    :per-page="perPage"
                    aria-controls="my-table"
                    class="pb-2"
                ></b-pagination>
                    <div
                        class="tab-pane show active"
                        v-show="viewMode == 'list'"
                        transition="fade"
                    >
                        <div class="row list">
                            <zs-list-transition
                                :showLoader="showLoader"
                                class="w-100"
                            >
                                <template v-slot:content>
                                    <door-card-item
                                        v-for="device in paginatedList"
                                        v-bind:key="device.Device.Id"
                                        :device="device"
                                    />
                                </template>
                                <template v-slot:empty>
                                    <empty-list :title="$t('doors.no_doors')" />
                                </template>
                            </zs-list-transition>
                        </div>
                    </div>

                    <div
                        class="tab-pane show active"
                        v-show="viewMode == 'card'"
                        transition="fade"
                    >
                        <div class="row listAlias" v-if="devicesList.length">
                            <door-large-card-item
                                v-for="device in paginatedList"
                                v-bind:key="device.Device.Id"
                                :device="device"
                            />
                        </div>
                        <empty-list v-else :title="$t('doors.no_doors')" />
                    </div>
                <!-- </transition> -->
                <b-pagination
                    v-model="currentPage"
                    :total-rows="devicesList.length"
                    :per-page="perPage"
                    aria-controls="my-table"
                    class="pb-2"
                ></b-pagination>
            <!-- </div> -->
            </div>
          </div>
        </b-col>
        </b-row>
        </b-container>
    </div>
</template>

<script>
import ListTransition from "@/components/common/ListTransition";
import DoorCardItem from "@/components/device/DoorCardItem";
import DoorLargeCardItem from "@/components/device/DoorLargeCardItem";
import EmptyList from "@/components/common/EmptyList";
import Select2 from "@/components/common/Select2";

import { mapGetters } from "vuex";

import { USER_GET_DEVICES } from "@/store/types/user";

export default {
    data() {
        return {
            filter: "",
            sortOrder: 1,
            showLoader: true,
            viewMode: "",
            currentPage: 1,
            perPage: 20,
            perPageOptions: [
                { id: 10, text: "10 " + this.$t("menu.per_page") },
                { id: 20, text: "20 " + this.$t("menu.per_page") },
                { id: 50, text: "50 " + this.$t("menu.per_page") },
            ],
        };
    },
    computed: {
        ...mapGetters(["getUserDevices"]),
        devicesList() {
            return this.getUserDevices
                .filter((item) =>
                    item.Device.Name.toLowerCase().includes(
                        this.filter.toLowerCase()
                    )
                )
                .sort((i1, i2) => {
                    if (this.sortOrder == 1) {
                        return i1.Device.Name.toUpperCase() >
                            i2.Device.Name.toUpperCase()
                            ? 1
                            : -1;
                    } else {
                        return i1.Device.Name.toUpperCase() <
                            i2.Device.Name.toUpperCase()
                            ? 1
                            : -1;
                    }
                });
        },
        paginatedList() {
            return this.devicesList.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            );
        },
    },
    created() {
        if(this.$session.get("pageView")){
            this.viewMode = this.$session.get("pageView");
        }else{
            this.viewMode = 'list';
        }
        this.$store
            .dispatch(USER_GET_DEVICES)
            .then(() => (this.showLoader = false));
    },
    methods: {
        pageView(view){
            this.$session.set("pageView", view);
            this.viewMode = view;
        }
    },
    components: {
        "door-card-item": DoorCardItem,
        "door-large-card-item": DoorLargeCardItem,
        EmptyList,
        "zs-list-transition": ListTransition,
        Select2,
    },
};

</script>
<style lang="sass" scoped>
.fade-enter-active,
.fade-leave-active
    transition: opacity .15s

    .fade-enter,
    .fade-leave-to
        opacity: 0
</style>
