<template>
    <b-col cols="12" md="4" xl="3">
        <div class="card">
            <router-link
                class="door-image-link"
                :to="{
                    name: 'door',
                    params: {
                        locationId: device.Device.LocationId,
                        doorId: device.Device.Id,
                        companyId: device.Device.CompanyId,
                    },
                }"
            >
                <img
                    :src="
                        device.Device.MediumImageUrl
                            ? device.Device.MediumImageUrl
                            : '/images/zesec-placeholder.png'
                    "
                    class="card-img-top"
                />
                <!-- <img
                    class="provider"
                    :src="
                        '/images/door_provider_' +
                        device.Device.ProviderType +
                        '.png'
                    "
                /> -->
            </router-link>
            <div class="card-body">
                <div class="row align-items-center">
                    <div class="col">
                        <!-- Title -->
                        <h4 class="mb-2 name text-truncate">
                            <router-link
                                :to="{
                                    name: 'door',
                                    params: {
                                        locationId: device.Device.LocationId,
                                        doorId: device.Device.Id,
                                        companyId: device.Device.CompanyId,
                                    },
                                }"
                                >{{ device.Device.Name }}</router-link
                            >
                        </h4>

                        <!-- Subtitle -->
                        <p class="card-text small text-muted">
                            {{
                                getDeviceProvider(device.Device.ProviderType)
                                    .key
                            }}
                        </p>
                    </div>
                    <div class="col-auto">
                        <!-- Dropdown -->
                    </div>
                </div>
            </div>
            <div class="card-footer card-footer-boxed">
                <div class="row align-items-center">
                    <div class="col">
                        <div class="row align-items-center no-gutters">
                            {{
                                $t("common.count_keys", {
                                    count: device.Keys
                                        ? device.Keys.length
                                        : device.KeysCount,
                                })
                            }}
                        </div>
                    </div>
                    <!-- <div class="col-auto">
                        <p class="card-text small" v-if="device.Device.Locked">
                            <span class="text-warning">●</span>
                            {{ $t("doors.locked") }}
                        </p>
                        <p class="card-text small" v-else>
                            <span class="text-success">●</span>
                            {{ $t("doors.unlocked") }}
                        </p>
                    </div> -->
                </div>
                <!-- / .row -->
            </div>
        </div>
    </b-col>
</template>
<script>
import { getDeviceProvider } from "@/handlers/enums";

export default {
    name: "door-large-card-item",
    props: ["device"],
    created() {},
    methods: {
        getDeviceProvider(id) {
            return getDeviceProvider(id);
        },
    },
};
</script>
<style scoped>
img.provider {
    position: absolute;
    left: 5px;
    bottom: 10px;
    width: 50%;
}
.door-image-link {
    position: relative;
}
</style>