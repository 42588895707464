var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"card mb-1"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row align-items-center"},[(_vm.device.Device.ProviderType == 5 && _vm.device.OnlineDevice.OnlineState == true)?_c('div',{staticClass:"col-auto"},[_c('router-link',{staticClass:"avatar avatar-online-new avatar-lg",attrs:{"to":{
                            name: 'door',
                            params: {
                                locationId: _vm.device.Device.LocationId,
                                doorId: _vm.device.Device.Id,
                                companyId: _vm.device.Device.CompanyId,
                            },
                        }}},[_c('img',{staticClass:"avatar-img rounded",attrs:{"src":_vm.device.Device.SmallImageUrl || '/images/zesec-placeholder.png'}})])],1):(_vm.device.Device.ProviderType == 5 && _vm.device.OnlineDevice.OnlineState == false)?_c('div',{staticClass:"col-auto"},[_c('router-link',{staticClass:"avatar avatar-offline-new avatar-lg",attrs:{"to":{
                            name: 'door',
                            params: {
                                locationId: _vm.device.Device.LocationId,
                                doorId: _vm.device.Device.Id,
                                companyId: _vm.device.Device.CompanyId,
                            },
                        }}},[_c('img',{staticClass:"avatar-img rounded",attrs:{"src":_vm.device.Device.SmallImageUrl || '/images/zesec-placeholder.png'}})])],1):_c('div',{staticClass:"col-auto"},[_c('router-link',{staticClass:"avatar avatar-lg",attrs:{"to":{
                            name: 'door',
                            params: {
                                locationId: _vm.device.Device.LocationId,
                                doorId: _vm.device.Device.Id,
                                companyId: _vm.device.Device.CompanyId,
                            },
                        }}},[_c('img',{staticClass:"avatar-img rounded",attrs:{"src":_vm.device.Device.SmallImageUrl || '/images/zesec-placeholder.png'}})])],1),_c('div',{staticClass:"col ml-n2"},[_c('h4',{staticClass:"mb-1"},[_c('router-link',{attrs:{"to":{
                                name: 'door',
                                params: {
                                    locationId: _vm.device.Device.LocationId,
                                    doorId: _vm.device.Device.Id,
                                    companyId: _vm.device.Device.CompanyId,
                                },
                            }}},[_vm._v(_vm._s(_vm.device.Device.Name))])],1),_c('p',{staticClass:"card-text small text-muted mb-1"},[_vm._v(" "+_vm._s(_vm.getDeviceProvider(_vm.device.Device.ProviderType) .key)+" ")])]),_c('div',{staticClass:"col-auto"},[_c('small',[_vm._v(" "+_vm._s(_vm.$t("common.count_keys", { count: _vm.device.Keys ? _vm.device.Keys.length : _vm.device.KeysCount, }))+" ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }